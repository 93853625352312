// vue共享创库
import Vue from 'vue'

import Vuex from "vuex"
// import * as actions from './actions'
// import * as getters from './getters'
// import mutations from './mutations.js';
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        userInfo: {},
        senceData: {},
        amount: {},
        findSeat: false,
        left_tickets: 0,
        already_tickets: 0
    },
    mutations: {
        // newUserIfon就是登陸後存起來的數據
        changeUserInfo(state, newUserIfon) {
            state.userInfo = newUserIfon
        },
        changeSenceData(state, senceData) {
            state.senceData = senceData
        },
        changeAmount(state, amount) {
            state.amount = amount
        },
        changeFindSeat(state, findSeat) {
            state.findSeat = findSeat
        },
        changeLeftTickets(state, left) {
            state.left_tickets = left
        },
        changeAlreadyTickets(state, already) {
            state.already_tickets = already
        },
    }
})

export default store
// 创建 store 实例
// export default new Vuex.Store({
//     actions,
//     getters,
//     store,
//     mutations,
// })