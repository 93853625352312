import Vue from 'vue';
import Head from './head';
import Foot from './foot';
import OnlineFooter from './onlinefooter';

const components = { Head, Foot,OnlineFooter };

components.install = () => {
  for (let name in components) {
    Vue.component(components[name].name, components[name]);
  }
};

export default components;