<template>
  <div class="chart-container">
    <!-- <cc-header :head-data="headData"></cc-header> -->
    <main>
      <div id="orderInfo">
        <div id="left" v-if="orderInfo">
          <p class="movie">影片：{{orderInfo.movie.name}}</p>
          <p class="movie">版本：{{orderInfo.movie.version.name}}</p>
          <p class="movie">等級：{{orderInfo.movie.grade.name}} </p>
          <p class="movie">支付狀態：{{orderInfo.is_payed==0?'待支付':'已支付'}}</p>
          <p class="movie">客戶手機號：{{orderInfo.customer.telphone}}</p>
        </div>
        <div id="middle" v-if="orderInfo">
          <p class="movie">場次：{{orderInfo.changci.start_date}} {{orderInfo.changci.start_time}} </p>
          <p class="movie">廳：{{orderInfo.room.name}}</p>
          <p class="movie">座位：
             <span v-for="item in orderInfo.orderseatsx" :key="item.id">
              {{item.position.split('|')[0]}}   排 {{item.position.split('|')[1]}} 號
             </span>
             <!-- <span v-for="item in JSON.parse(orderInfo.seats)" :key="item.position">
               test
              {{item.position.split('|')[1]}}   排 {{item.position.split('|')[0]}} 號
             </span> -->
          </p>
          <p class="movie">總價：NT {{orderInfo.total_price}}   客戶：{{orderInfo.customer.name}}</p>
        </div>
        <div id="right">
          <el-input
            v-model="onlineNo"
            @focus="show"
            autofocus="true"
            clearable
            data-layout="normal"
            label="票券信息"
            placeholder="請輸入票券號"
            style="width: 250px"
          ></el-input>
          <p style="margin-top: 20px">
            <el-button @click="query">查詢</el-button>
            <el-button v-if="orderInfo && orderInfo.is_payed==0" @click="pay">確認支付</el-button>
          </p>
        </div>
      </div>
      <div id="table" style="margin-top: 20px;clear:both;">
        <el-table
          :data="tableData"
          stripe
          slot="暫無數據"
          style="width: 80%; margin-left: 10%; margin-top: 20px; clear: both"
        >
          <el-table-column prop="online_no" label="單號">
          </el-table-column>
          <el-table-column prop="movie.name" label="電影">
          </el-table-column>
          <el-table-column prop="changci" label="場次" width="180">
            <template slot-scope="props">
              {{props.row.changci.start_date}} {{props.row.changci.start_time}}
            </template>
          </el-table-column>
          <el-table-column prop="room.name" label="廳" width="60">
          </el-table-column>
           <el-table-column prop="customer.name" label="用戶" width="80"> </el-table-column>
          <el-table-column prop="customer.telphone" label="用戶手機號" width="100"> </el-table-column>
          <el-table-column prop="orderseatsx.length" label="數量" width="40"> </el-table-column>
          <el-table-column prop="total_price" label="金額" width="60"></el-table-column>
          <el-table-column prop="created_at" label="預訂時間"> </el-table-column>
          <el-table-column label="支付狀態" width="80">
                <template slot-scope="props">
              {{props.row.is_payed==0? '' :'已支付'}}
            </template>
          </el-table-column>
            <el-table-column label="退票狀態" width="80">
                <template slot-scope="props">
              {{props.row.is_refund==0? '' :'已退票'}}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:20px;margin-left:10%;" @current-change="changePage"
  background
  layout="prev, pager, next"
  :total="total">
</el-pagination>
      </div>
    </main>
    <cc-onlinefooter></cc-onlinefooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      onlineNo: "",
      orderInfo: "",
      tableData: [],
      headData: {}, //頭部數據
      total:0,
      page:1
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    show(e) {},
    changePage(page){
      this.page=page
      this.init()
    },
    init() {
      this.$api.request
        .queryOnlineList({
          page: this.page,
          date: "",
        })
        .then((res) => {
          this.tableData = res.data;
          this.total=res.total
        });
    },
    query() {
      this.$api.request
        .queryOnline({
          online_no: this.onlineNo,
        })
        .then((res) => {
          this.orderInfo = res;
        });
    },
    pay() {
      var vm = this;
      this.$confirm("是否確認收到 NT "+this.orderInfo.total_price +"?", '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        vm.$api.request
        .payOnlineOrder({
          online_no: vm.onlineNo,
        })
        .then((res) => {
          vm.$message({
            message: '付款成功',
            type: "success",
          });
        });
      })
      .catch(() => {
         vm.$message({
            message: '已取消操作',
            type: "warning",
          });
      });
    },
  },
};
</script>
<style lang="less">
.chart-container {
  height: calc(100% - 40px);
  background: linear-gradient(225deg, #0c0c0c, #1b3641);
  main {
    padding: 5px 0;
    float: left;
    height: calc(100% - 227px);
    background: linear-gradient(225deg, #0c0c0c, #1b3641);
    width: 100%;
    #orderInfo {
      width: 80%;
      margin-left: 10%;
      margin-top: 20px;
      margin-bottom: 20px !important;
      height:100px;
      #left,
      #middle,
      #right {
        float: left;
        p {
          width: 300px;
          color: white;
          font-size: 20px;
        }
      }
    }
  }
  .right-fixed {
    height: 100%;
    width: 130px;
    float: right;
    margin-top: 5px;
    padding: 0 10px;
    background: linear-gradient(150deg, #0c0c0c, #1b3641);
    h1 {
      line-height: 50px;
      font-weight: 400;
      color: #fff;
      font-size: 18px; // height: 100%;
      text-align: center;
    }
    div {
      color: #777;
      height: 35px;
      background: #eee;
      margin-top: 4px;
      padding: 5px;
      border-radius: 2px;
      text-align: center;
      line-height: 35px;
    }
  }
}
</style>