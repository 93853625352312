// 頁面貫穿數據使用

const KEY = "ticData"

// 保存TicData
export function setTicData(ticData) {
    sessionStorage.setItem(KEY, ticData)
}
// 获取TicData
export function getTicData() {
    return sessionStorage.getItem(KEY)
}
// 移除TicData
export function removeTicData() {
    sessionStorage.removeItem(KEY)
}

const seatKEY = "seatData"
// 保存seatData
export function setSeatData(seatData) {
    sessionStorage.setItem(seatKEY, seatData)
}
// 获取seatData
export function getSeatData() {
    return sessionStorage.getItem(seatKEY)
}
// 移除seatData
export function removeSeatData() {
    sessionStorage.removeItem(seatKEY)
}


const ticKEY = "ticType"
// 保存seatData
export function setTicType(ticType) {
    sessionStorage.setItem(ticKEY, ticType)
}
// 获取seatData
export function getTicType() {
    return sessionStorage.getItem(ticKEY)
}
// 移除seatData
export function removeTicType() {
    sessionStorage.removeItem(ticKEY)
}