<template>
  <div class="cc-header" @submit="handleSubmit">
    <div>
      <p v-if="Object.keys(validate).length">
        <span>廳別：<i>{{validate.hall}}</i></span>
        <span>片名：<i>{{validate.child2.movie.name}}</i></span>
        <span>版本：<i>{{validate.child2.movie.version.name}}</i></span>
        <span>場次：<i>{{validate.filmSence}}</i></span>
      </p>
      <p v-else>
        <span>廳別：<i></i></span>
        <span>片名：<i></i></span>
        <span>版本：<i></i></span>
        <span>場次：<i></i></span>
      </p>
    </div>
    <div  v-if="showTictime">
      <p>
        <span v-if="showTicType">可選座位：<i>{{amount.validSeat}}</i> &nbsp;&nbsp;&nbsp;&nbsp; 已選座位：<i>{{haveChoose}}</i></span>   
        <span>已售票數：<i>{{already.already}}</i></span>
        <span>剩餘票數：<i>{{left.left}}</i></span>
        <span>應收金額：<i>{{amount.amounts}}</i></span>
      </p>
    </div>
    <div>
      <p class="last-p">
        <span>{{userInfo.time}}【{{userInfo.name}}】</span>
        <span class="type">訂票模式</span>
        <span class="date" v-if="showTictime">票日期：<i>{{validate.child2.start_date}}</i></span>
      </p>
    </div>
  </div>
</template>

<script>
// import { getSenceData } from "@/utils/dataCross";
export default {
  name: 'cc-header',
  props: {
    inline: {
      type: Boolean
    },
    headData: {
      type: Object,
      
    },
    readonly: {
      type: Boolean
    },
    admin: {
      type: String
    },
    haveChoose: {
      type: Number
    },
  },
  data() {
    return {
      // validate: {},
      showTictime: false,
      showTicType: false,
    }
  },
  mounted() {
    if(this.$route.path == "/chart/chooseTic" || this.$route.path == "/chart/rowSeat"){
      this.showTictime = true
    }
    if(this.$route.path == "/chart/rowSeat"){
      this.showTicType = true
    }
  },

  computed: {
    // 用户信息
    userInfo(){
      return this.$store.state.userInfo
    }, 

    //頭部信息
    validate(){
      return this.$store.state.senceData
    },

    //金額
    amount(){
      return this.$store.state.amount
    },
    //剩餘票數
    left(){
     return this.$store.state.left_tickets
    },
    //已售票數
    already(){
     return this.$store.state.already_tickets
    }
  },

  watch:{
    // haveChoose(){
    //   window.console.log(this.amount.validSeat, this.haveChoose)
    //   if(this.haveChoose > this.amount.validSeat*1){
    //     this.$message({
    //       message: `當前可選座位為${this.amount.validSeat}`,
    //       type: 'warning'
    //     });
    //     return false
    //   }
    // }
  },

  methods: {
    handleSubmit() {
      let pass = true;
      if (this.model) {
        for (let key in this.model) {
          let val = this.model[key];

          if (this.validate[key]) {
            this.validate[key].vm.$emit('validate', val);
            // console.log(this.validate[key].vm.error)

            if (this.validate[key].vm.error) {
              pass = false;
            }
          }
        }
      }

      if (pass) {
        this.$emit('submit');
      }
    }
  }
}
</script>

<style lang="scss">
// @import "~@/styles/base";
.cc-header {
  padding: 30px 20px;
  display: flex;
  height: 100px;
  background: #1b3641;
  // border-top: 1px solid #008b9f;
  border-bottom: 2px solid #008b9f;
  // justify-content: space-between;
  div{
    flex: 1;
    p{
      display: flex;
      flex-direction: column;
      span{
        font-size: 18px;
        display: block;
        margin: 3px 0;
        color: #fff;
        i{
          color: orange;
        }
      }
      .amount, .type{
        letter-spacing: 2px;
        font-size: 18px;
        // padding: 0 10px;
        font-weight: 600;
      }
    }
    .last-p{
     text-align: right;
      .type{
        letter-spacing: 2px;
        font-size: 18px;
        padding: 0 10px;
        font-weight: 600;
      }
      .type{
        color: orange;
      }
    }
  }
}
</style>
