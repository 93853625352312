<template>
<div class="choose-tic">
  <cc-header></cc-header>
  <main>
    <div class="left-tictype">
      <h1>票種</h1>
      <div class="tictype" style="overflow-y:scroll;">
        <!-- old -->
        <!-- <div v-for="(item, index) in checkTicData" :key="index" @click="getTicType(item)">
            <p>
              {{item.tickettype.name}}  
            </p>
          </div> -->
        <!-- new -->
        <div v-for="(item, index) in checkTicData" :key="index" @click="getTicTypeNew(item)">
          <p>
            {{item.tickettype.name}}
          </p>
        </div>
      </div>
      <Calculation v-if="keyShow" :fatherNum="hospitalInfo[this.diffType]" @confirmEvent="confirmEvent" @numberEvent="numberEvent" @clearEvent="clearEvent" @deleteEvent="deleteEvent"></Calculation>
    </div>
    <!-- <div class="sure">
        <el-button type="primary">
          確定
        </el-button>
      </div> -->

    <div class="right-checkedtic" @click="hideNumericKeyboard">
      <h1>已 選：
        <span v-for="(item, index) in hadChoose" :key="index">
            {{item.name}}+{{item.count}},
          </span>
      </h1>
      <div class="checkedtic" style="overflow-x:scroll;">
        <div v-for="(item, index) in checkedTicData" :key="index" @click="deleTicType(index)">
          <p>
            {{item.tickettype ? item.tickettype.name : ''}}
          </p>
        </div>
      </div>
    </div>
  </main>

  <cc-footer></cc-footer>
</div>
</template>

<script>
// 导入 echarts
// import echarts from "echarts";
import {
  setTicData,
  setTicType
} from "@/utils/dataCross";
import Calculation from './../../../components/NumKeypad.vue';
// 引入vuex store
import store from '@/store/store'
export default {
  components: {
    Calculation
  },
  data() {
    return {
      hospitalInfo: {
        bedNum: '', // 编制床位数
        bedOpen: '', // 开放床位数
      },
      keyShow: false, // 控制数字键盘显示/隐藏
      diffType: 'bedNum', // 区分点击弹出数字键盘的是哪一行
      checkTicData: [],
      checkedTicData: [],
      hadChoose: [],
      checkTicId: {},
      currentItem:''
      // time: ['敬老票','敬老票','敬老票','敬老票','全票','全票','全票','全票','學生票','學生票',]
    };
  },
  methods: {
    hideNumericKeyboard() {
      this.keyShow = false
    },
    // 点击数字键
    numberEvent(res) {
      // alert(res)
      for(var i=0;i<res;i++){
        this.getTicType(this.currentItem)
      }
      this.keyShow=false
      // console.log('数字键',res)
      // console.log('数字键：', res);
      // this.hospitalInfo[this.diffType] = `${this.hospitalInfo[this.diffType]}${res}`;
    },
    // 点击清除键
    clearEvent(res) {
      // console.log('清除键：', res);
      // this.hospitalInfo[this.diffType] = res; // 清除，子组件传过来的是‘’
    },
    // 点击删除键
    deleteEvent(res) {
      // console.log('删除键：', res);
      // this.hospitalInfo[this.diffType] = res;
    },
    // 点击确认键
    confirmEvent(res) {
      // console.log('确认键：', res);
      this.keyShow = false; // 点击确认，收起键盘
    },
    //獲取播放聼和場次數據
    getInitData() {
      let obj = this.$store.state.senceData
      this.$api.request.getSeatTypes({
        changci_id: obj.child2.id,
        movie_id: obj.child2.movie_id
      }).then(res => {
        this.checkTicData = res.ticketType
        for (let i = 0; i < this.checkTicData.length; i++) {
          // this.checkTicId = 
        }
        // window.console.log(this.hadChoose, 989898)
        //new hxf
        store.commit("changeLeftTickets", {
          left: res.nums.left
          // left_tickets: res.nums.left
        })
        store.commit("changeAlreadyTickets", {
          already: res.nums.already
          // already_tickets: res.nums.already
        })
      })
    },
    //票种数量计算
    getRepeatNum(arr) {
      var obj = {};
      for (var i = 0, l = arr.length; i < l; i++) {
        var item = arr[i];
        obj[item] = (obj[item] + 1) || 1;
      }
      return obj;
    },

    //获取票种数量最终方法
    getReson() {
      let arr = this.checkedTicData.map(item => {
        return item.tickettype.name
      })
      let obj = this.getRepeatNum(arr)
      this.hadChoose = []
      for (let i in obj) {
        this.hadChoose.push({
          name: i,
          count: obj[i]
        })
      }
      setTicType(JSON.stringify(this.hadChoose))
    },
    getTicTypeNew(item) {
      // alert('in')
      this.keyShow = true
      this.currentItem = item
    },
    //點擊選擇票種
    getTicType(item) {
      let itemIndex = this.checkedTicData.findIndex(item1 => {
        return item.id == item1.id
      })
      if (itemIndex >= 0) {
        this.checkedTicData.splice(itemIndex, 0, item)
      } else {
        this.checkedTicData.push(item)
      }
      this.getAmount()
      this.getReson()
      setTicData(JSON.stringify(this.checkedTicData))
    },

    //刪除票種
    deleTicType(index) {
      this.checkedTicData = this.checkedTicData.filter((item1, index1) => {
        if (index1 != index) {
          return item1
        }
      })
      this.getAmount()
      // this.calcTicNum()
      this.getReson()
      setTicData(JSON.stringify(this.checkedTicData))
    },

    //計算價格
    getAmount() {
      let amounts = 0;
      this.checkedTicData.forEach(val => {
        let num = Number(val.money)
        amounts += num
      })
      store.commit("changeAmount", {
        amounts: amounts,
        validSeat: this.checkedTicData.length
      })

      this.checkedTicData.sort()
    },
  },
  mounted() {
    store.commit("changeAmount", {
      amounts: 0,
      validSeat: this.checkedTicData.length
    })
    // 数据获取


    // 使用刚指定的配置项和数据显示图表。

  },

  created() {
    // 数据获取
    this.getInitData()
  },
};
</script>

<style lang="less">
.choose-tic {
  height: calc(100% - 40px);
  background: linear-gradient( 225deg, #0c0c0c, #1b3641);
  main {
    box-sizing: border-box;
    padding: 0 20px; // float: left;  
    height: calc(100% - 227px);
    background: linear-gradient( 225deg, #0c0c0c, #1b3641);
    width: 100%;
    .el-button {}
    .left-tictype {
      // 
      width: 30%;
      height: 100%;
      float: left;
      h1 {
        color: #1c9ac0;
        margin: 10px 0; // text-align: center;
        font-size: 20px;
        letter-spacing: 5px;
      }
      .tictype {
        overflow: hidden; // background: #0e0e0e;
        border: 2px solid #008b9f;
        padding: 10px 10px;
        height: 80%;
        display: flex;
        flex-direction: column; // justify-content: space-around;
        align-items: center;
        div {
          // display: inline-block;
          width: 85%;
          border-radius: 2px;
          height: 12%;
          background: #1c9ac0;
          margin: 5px 0;
          p {
            text-align: center;
            line-height: inherit;
            font-size: 20px;
            font-weight: bold;
            margin-top: 3%;
            letter-spacing: 3px;
            color: #ddd;
          }
        }
      }
    }
    .right-checkedtic {
      height: 100%;
      width: calc(60% - 20px);
      float: right;
      h1 {
        font-size: 20px;
        color: #1c9ac0;
        margin: 10px 0; // text-align: left;
        // letter-spacing: 5px;
        span {
          font-weight: 400;
          font-size: 18px;
        }
      }
      .checkedtic {
        overflow: hidden;
        border: 2px solid #008b9f;
        padding: 10px 20px;
        height: 80%;
        display: flex;
        flex-direction: column; // justify-content: space-around;
        align-content: flex-start;
        flex-wrap: wrap;
        div {
          width: 30%;
          border-radius: 2px;
          height: 10%;
          background: #1c9ac0;
          color: #ddd;
          margin: 8px 5px 0;
          padding: 5px;
          border-radius: 2px;
          p {
            text-align: center;
            line-height: inherit;
            font-size: 20px;
            margin-top: 3%;
            font-weight: bold;
            letter-spacing: 3px;
            color: #ddd;
          }
        }
      }
    }
  }
}
</style>
