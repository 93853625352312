<template>
  <div class="cc-footer" @submit="handleSubmit">
    <div class="foot-left" v-if="showWord">
      <p>請選擇 [電影場次] 。</p>
      <!-- <p>( 注：* 為自由座廰別 )</p> -->
    </div>
    <div class="foot-left2" v-if="showSeat">
      <p><i class="iconfont blue ">&#xe978;</i><span>：已選</span></p>
      <p><i class="iconfont grey">&#xe978;</i><span>：可選</span></p>
      <p><i class="iconfont red">&#xe978;</i><span>：已售</span></p>
    </div>
    <!-- <div  v-if="showSeat"  class="foot-left3">
      確定
    </div> -->
    <div class="foot-right">
      <span class="box" v-if="!showSeat" @click="cashboxOpen"><i class="iconfont">&#xe600;</i><br/>錢箱</span>
       <span class="box" @click="reloadx"><i class="iconfont">&#xe617;</i><br/>刷新</span>
      <!-- <span class="box" @click="pre"><i class="iconfont">&#xe64e;</i><br/>上一步</span> -->
      <!-- <span class="box"  @click="next" v-if="showNext"><i class="iconfont">&#xe62f;</i><br/>下一步</span> -->
      <span class="box"  @click="goHome"><i class="iconfont">&#xe603;</i><br/>主選彈</span>
    </div>
  </div>
</template>

<script>
// import {getTicType} from '@/utils/dataCross'
// 引入vuex store
import store from '@/store/store'
export default {
  name: 'cc-onlinefooter',
  props: {
    inline: Boolean,
    model: Object,
    readonly: Boolean,
    seatChoose: Number,
    seatCanChoose: Number,
  },
  data() {
    return {
      validate: {},
      showWord: false,
      showSeat: false,
      showNext: true
    }
  },
  mounted() {
    if(this.$route.path == "/chart"){
      this.showWord = true
    }
    if(this.$route.path == "/chart/rowSeat"){
      this.showSeat = true
    }
    if(this.$route.path == "/chart/checkTic"){
      this.showNext = false
    }
    // console.log(this.model)
    /*this.$nextTick(() => {
      this.$emit('ready', this.model);
    })*/
  },
  computed: {
    //金額
    amount(){
      return this.$store.state.amount
    },
    findSeat(){
      return this.$store.state.findSeat
    }
  },
  methods: {
    //刷新
    reloadx(){
      window.location.reload()
    },
    //打開錢箱
    cashboxOpen(){
      window.console.log('打開錢箱')
      window.CashboxOpen()
    },

    pre(){
      this.$router.go(-1)
      switch (this.$route.path){
        case "/chart/chooseTic": 
          this.$router.push("/chart")
          break;
        case "/chart/rowSeat": 
          this.$router.push("/chart/chooseTic")
          break;
        case "/chart/checkTic": 
          this.$router.push("/chart/chooseTic")
          break;
      }
    },
    next(){
      window.console.log(this.amount, this.seatChoose)
      if(this.amount.validSeat && this.seatChoose){
        if(this.amount.validSeat > this.seatChoose || this.amount.validSeat < this.seatChoose){
          this.$message({
            message: '請保持已選座位數量與可選座位數量一致！',
            type: 'warning'
          });
          return false
        }
      }

      switch (this.$route.path){
        case "/chart/chooseTic": 
          if(this.amount.validSeat > 0){
            this.$router.push("/chart/rowSeat")
          }else{
            this.$message({
              message: '請先選擇票種！',
              type: 'warning'
            });
          }
          break;
        case "/chart": 
        window.console.log(this.findSeat,89898)
          if(this.findSeat){
            this.$router.push("/chart/rowSeat")
          }else{
            this.$router.push("/chart/chooseTic")
          }
          break;
        case "/chart/rowSeat": 
          if(this.findSeat){
            this.$router.push("/index")
          }else
          if(this.seatChoose){
            this.$router.push("/chart/checkTic")
          }else{
            this.$message({
              message: '請先選擇座位！',
              type: 'warning'
            });
          }
          
          break;
      }
      // this.$router.push("/chart/chooseTic")
    },
    goHome(){
        store.commit("changeAmount",{})
        this.$router.push({name:"home",params:{refresh:1}})
    },
    handleSubmit() {
      // console.log(this)

      let pass = true;
      if (this.model) {
        for (let key in this.model) {
          let val = this.model[key];

          if (this.validate[key]) {
            this.validate[key].vm.$emit('validate', val);
            // console.log(this.validate[key].vm.error)

            if (this.validate[key].vm.error) {
              pass = false;
            }
          }
        }
      }

      if (pass) {
        this.$emit('submit');
      }
    }
  }
}
</script>

<style lang="scss" scope>
// @import "~@/styles/base";
.cc-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: #1b3641;
  // display: flex;
  // justify-content: space-between;
  .foot-left{
    float: left;
    position: relative;
    margin: 25px 20px;
    
    p{
      font-size: 18px;
      color: #fff;
    }
  }
  .foot-left2{
    float: left;
    margin: 10px 20px;
    p{
      margin-top: 5px;
      span{
        color: #fff;
      }
    }
    .red{
      color: #e76a42;
    }
    .blue{
      color: #03b1d4;
    }
    .grey{
      color: #bfbfbf;
    }
  }
  .foot-left3{
    float: left;
    margin: 20px 20px 0;
    color: #fff;
    // border: 1px solid #ccc
    background: #03b1d4;
    padding: 5px 20px;
    cursor: pointer;
  }
  .foot-right{
    float: right;
    display: flex;
    margin: 10px 20px;
    span{
      cursor: pointer;
      font-size: 14px;
      color: #eee;
      text-align: center;
      margin: 0 10px;
      i{
        font-size: 40px;
        color: #50a0c2;
        display: inline-block;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
