import axios from 'axios'
import Qs from 'qs'
import { Message } from 'element-ui'; //消息提示
// 导入token 保存方法
// import { getToken } from "./token"
window.console.log(process.env.VUE_APP_BASE_API,9999)
const http = axios.create({
  timeout: 50000,
  baseURL: process.env.VUE_APP_BASE_API,
  paramsSerializer: params => Qs.stringify(params, { 
    arrayFormat: 'repeat',
  }),
  withCredentials: false,
})

http.interceptors.request.use(
  config => {
    config.data = {
      ...config.data
    }
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : undefined}`
    }
  
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    const {data} = response
    if (response.status === 200) {
      return data
    }else{
      window.console.log(response,9999)
    //   this.$message({
    //     message: msg,
    //     type: 'warning'
    //   });
    }
    // message && Toast(message)
    // if (code === 302) {
    //   return router.replace({
    //     path: '/login',
    //     query: { redirect: encodeURI(router.history.current.fullPath) }
    //   })
    // }
    // return Promise.reject(response.data)
  },
  error => {
    window.console.log(error.response,'error')
    Message({
      message: error.response.data.msg,
      type: 'warning',
      showClose: true,
    });
    return Promise.reject(error)
  }
)

export default http
