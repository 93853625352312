<template>
  <div class="choose-seat">
 
    <cc-header :have-choose="haveChoose.length"></cc-header>

    <main>
      <div class="seat">
        <div>
          <span style="position:fixed;left:49%;margin-top:6px;color:white;">銀幕</span>
          <img src="./../../../assets/ym2.png" style="width:70%;margin-left:15%;margin-top:5px;margin-bottom:5px;"/>
         </div> 
          <table>
            <thead> 
              <!-- <tr>
                <th> </th>
                <th v-for="(item, index) in numList2" :key="index">
                  {{item}}
                </th>
              </tr> -->
              
            </thead>
            <tbody>
                <tr v-for="(item, index) in seatData" :key="index">
                    <td>{{item.y}}</td>
                    <td v-for="(item2, index2) in item.x" :key="index2">
                        <div @click="changeColor(item, item2)" class="seat_box">
                            <!-- <i class="iconfont" :class="[item2.choose ? 'red' : item2.already ? 'grey' : 'blue']" v-if="item2.position && !item2.cover_url">&#xe978;</i>
                            <img style="width:50px;" :src="item2.cover_url" alt=""  v-if="item2.position && item2.cover_url">
                            <p :class="[item2.choose ? 'red-p' : item2.already ? 'grey-p' : 'blue-p']">{{item2.position}}</p> -->
                            <i class="iconfont" :class="[item2.choose ? 'red' : item2.already ? 'grey' : 'blue']" v-if="item2.position && !item2.cover_url">&#xe978;</i>
                            <img style="width:60px;" :src="item2.cover_url" alt=""  v-if="item2.position && item2.cover_url">
                            <p :class="[item2.choose ? 'blue-p' : item2.already ? 'red-p' : 'grey-p']">{{item2.position}}</p>
                        </div>
                    </td>
                </tr>
            </tbody>
          </table>
      </div>
    </main>
    
    <cc-footer :seat-choose="haveChoose.length"></cc-footer>
  </div>
</template>

<script>
// 导入 echarts
// import echarts from "echarts";
import {setSeatData} from "@/utils/dataCross";
// 导入接口
// import { dataTitle, dataStatistics } from "../../../api/chart";
// 引入vuex store
import store from '@/store/store'
export default {
  data() {
    return {
      numList:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18],
      numList2:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
      seatData: [],
      haveChoose: []
    //   time: ['敬老票','敬老票','敬老票','敬老票','全票','全票','全票','全票','學生票','學生票',]
    };
  },
  computed: {
    //金額
    amount(){
      return this.$store.state.amount
    },

    //座位快查
    findSeat(){
      return this.$store.state.findSeat
    }
  },
  methods:{
    getTicketData() {
      let obj = this.$store.state.senceData
      this.$api.request.getSeatTypes({
        changci_id: obj.child2.id,
        movie_id: obj.child2.movie_id
      }).then(res => {
        // this.checkTicData = res.ticketType
        // for (let i = 0; i < this.checkTicData.length; i++) {
        //   // this.checkTicId = 
        // }
        // window.console.log(this.hadChoose, 989898)
        //new hxf
        store.commit("changeLeftTickets", {
          left: res.nums.left
          // left_tickets: res.nums.left
        })
        store.commit("changeAlreadyTickets", {
          already: res.nums.already
          // already_tickets: res.nums.already
        })
      })
    },
    //獲取播放聼和場次數據
    getInitData(){
      let obj = this.$store.state.senceData
      this.$api.request.getSeats({changci_id: obj.child2.id, room_id: obj.child2.room_id}).then(res => {
        this.seatData = res
        this.seatData = this.seatData.map(item => {
          item.x.forEach(item1 => {
            this.$set(item1, 'choose', false)
          });
          return item
        })
      })
      
    },

    //改变座位状态
    changeColor(item, item2){
      if(this.findSeat){
        this.$message({
          message: '座位快查不可選座！',
          type: 'warning'
        });
        return false
      }
      if(item2.already){
        this.$message({
          message: '該座位已售，請選擇可選座位！',
          type: 'warning'
        });
        return false
      }
      if(item2.choose){
        item2.choose = false
      }else
      if(this.haveChoose.length >= this.amount.validSeat){
        this.$message({
          message: `當前可選座位為${this.amount.validSeat}`,
          type: 'warning'
        });
        return false
      }else{
        item2.choose = true
      }
      this.haveChoose = []
      // item2.choose = !item2.choose
      this.seatData.forEach(item => {
        item.x.forEach(item1 => {
          if(item1.choose){
            this.haveChoose.push(item1)
          }
        });
      })
      setSeatData(JSON.stringify(this.haveChoose))
      window.console.log(item, item2,this.haveChoose)
    }
  },
  mounted() {
  },
  watch: {
    // haveChoose(){
    //   window.console.log(this.amount.validSeat, this.haveChoose.length)
    //   if(this.haveChoose.length > this.amount.validSeat){
    //     this.$message({
    //       message: `當前可選座位為${this.amount.validSeat}`,
    //       type: 'warning'
    //     });
    //     return false
    //   }
    // }
  },
  created(){
    this.getInitData()
    this.getTicketData()
  },
};
</script>

<style lang="less">
.choose-seat {
  height: calc(100% - 40px);
  background: linear-gradient(
      225deg,
      #0c0c0c,
      #1b3641
    );
  main{
    box-sizing:border-box;
    padding: 5px 20px;
    // float: left;  
    height: calc(100% - 227px);
    background: linear-gradient(
      225deg,
      #0c0c0c,
      #1b3641
    );
    width: 100%;
    .left-num{
        float:left;
        p{
            color: #fff;
            font-size: 12px;
            margin: 3px 1px;
            height: 24px;
            line-height: 24px;
        }
    }
    .seat{
        // float:left;
        height: 100%;
        width: 100%;
        background: #efefef;
        margin: auto;
        overflow-y: scroll;
        table{
            width: 100%;
            height: 100%;
            
            // margin-left: 50%;
        }
        
        tr{
            text-align: center;
            // border-bottom: 1px solid #ccc;
            // display: block;
            th{
              width:30px;
              padding-left:5px;
              text-align:left;
            }
        }
        td{
            // display: inline-block;
            // text-align: center;
            width: 30px;
            // height: 18px;
            // padding: 5px 0;
            cursor: pointer;
            .seat_box{
              position:relative;
              // p{
              //   position: absolute;
              //   top: 10px;
              //   left: 5px;
              //   background: #fff;
              // }
            }
            // border-bottom: 1px solid #ccc;
            p{
                top: 15px;
                left: 5px;
                background: #fff;
                position: absolute;
                font-size: 18px;
                font-weight: 600;
                height: 20px;
                line-height: 20px;
                transform: scale(0.84);
                color: #fff;
                
            }
            .red-p{
              background: #e76a42;
            }
            .grey-p{
              background: #777 !important;
            }
            .blue-p{
              background: #03b1d4;
            }
            i{
                font-size: 28px;
            }
            img{
              display: inline-block;
              width: 50px;
            }
            .red{
                color: #e76a42;
            }
            .blue{
                color: #03b1d4;
            }
            .grey{
                color: #bfbfbf;
            }
        }
        
    }
  }
  
}
</style>
