import Vue from 'vue'
import App from './App.vue'
import Cc from '@/components/index';
//  导入路由
import router from './router/router.js'
//  导入饿了么
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 导入全局过滤器
import './filters/filter'
// 导入自定义指令
import './directives/directives'
//  导入全局样式
import '../src/style/base.css'
// 导入Vuex
import store from './store/store'
// import './assets/style/sass/element-variables.scss'
import './style/common.scss'
import './assets/font/iconfont.js'
import './assets/font/iconfont.css'
import api from './api/index'
// import preventReClick from '@/utils/preventReClick'
// import VueBarcodeScanner from 'vue-barcode-scanner'


// 注册
Vue.use(ElementUI);
Vue.use(Cc);
Vue.use(api);
// Vue.use(VueBarcodeScanner);

Vue.config.productionTip = false
const state = {
    code: {
        a: 1,
        b: 'sss'
    }
}

const mutations = {
    getDate(state, num) {
        state.code.a += num
            //赋值后直接存起来
        sessionStorage.setItem('code', JSON.stringify(state.code))
    }
}
for (var i in state) {
    //遍历对象获取存值 
    sessionStorage.getItem(i) ? state[i] = JSON.parse(sessionStorage.getItem(i)) : false
}

new Vue({
    render: h => h(App),
    router,
    store,
    mutations
}).$mount('#app')