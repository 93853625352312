<template>
  <div class="check-tic">
    <!-- <cc-header></cc-header> -->

    <main>
      <div class="seat">
          <p>廳名：{{validate.hall}}</p>
          <p>片名：{{validate.child2.movie.name}}</p>
          <p>版本：{{validate.child2.movie.version.name}}</p>
          <p>場次：{{validate.filmSence}}</p>
          <p>票種：<span v-for="(item, index) in ticTypeList" :key="index">{{item.name}}+{{item.count}},</span></p>
          <p>座位：<span v-for="(item, index) in position" :key="index">{{item.position}},</span></p>
          <p>應收金額：{{amount.amounts}}</p>
      </div>
      <div class="bottom-btn">
        <el-button @click="cancle">取消交易</el-button>
        <el-button @click="printTic" v-preventReClick>列印票券</el-button> 
      </div>
      
    </main>
    
    <cc-footer></cc-footer>
  </div>
</template>

<script>
// 导入 echarts
// import echarts from "echarts";
// import pageHead from "../pageHead.vue";
// 引入vuex store
import store from '@/store/store'
import {getTicData, getSeatData, getTicType, removeTicType} from "@/utils/dataCross";
export default {
  data() {
    return {
      ticTypeList: JSON.parse(getTicType()),
      position: [],
      ticData: {
          hall: 'A',
          filmName: '少年pai奇遇记',
          edit: 'v1.1.1',
          scene: '12',
          ticType: '全票',
          seat: '12-02',
          amount: '121.00'
      },
      seatArr: [],
    //   time: ['敬老票','敬老票','敬老票','敬老票','全票','全票','全票','全票','學生票','學生票',]
    };
  },
  methods:{
    //打印票券
    printTic(){
      this.getInitData()
      // window.console.log(this.seatData.print, 888888,)
      // let arr = []
      // arr = this.seatArr
      // window.console.log('打印數據：',JSON.stringify(this.seatData.print))
      // window.SetDate(JSON.stringify(this.seatData.print))
      // let _this = this
      // setTimeout( function(){
      //   let result = window.SetDate()
      //   window.console.log(result, '是否打印成功')
      //   if(result == '00'){
      //     _this.$message({
      //       message: '打印成功！',
      //       type: 'success'
      //     });
      //     _this.$router.push("/index")
      //   }else{
      //     _this.$message({
      //       message: '打印失敗！',
      //       type: 'warning'
      //     });
      //   }
      // }, 5000)

      
    },

    //取消交易
    cancle(){
      this.$router.push("/index")
      sessionStorage.clear()
      store.commit("changeAmount",{})
    },
     //創建訂單
    getInitData(){
      let obj = this.$store.state.senceData
      window.console.log(obj)
      let arr1 = JSON.parse(getTicData())
      let arr2 = JSON.parse(getSeatData())
      // let seatArr = []
      this.seatArr = arr1.map(item => {
        return {money: item.money,ticket_type_id: item.ticket_type_id}
      })
      arr2.forEach((item, index) => {
        this.seatArr[index].seat_id = item.id || ''
        this.seatArr[index].position = item.position || ''
        this.seatArr[index].seat_type_id = item.seat_type_id || ''
        this.seatArr[index].x = item.x || ''
        this.seatArr[index].y = item.y || ''
      })
      this.position = arr2.map(item => {
        return {position: item.position}
      })
      // let params = {
      //   changci_id: obj.child2.id, 
      //   room_id: obj.child2.room_id,
      //   seats: seatArr
      // }
      this.$api.request.create({
        changci_id: obj.child2.id, 
        room_id: obj.child2.room_id,
        seats: this.seatArr,
        movie_id: obj.child2.movie_id}).then(res => {
        this.seatData = res
        window.console.log('打印數據：',JSON.stringify(this.seatData.print))
        // window.SetDate(JSON.stringify(this.seatData.print))
        window.PrintMovieTicket(JSON.stringify(this.seatData.print))

        // let _this = this
        // setTimeout( function(){
        //   let result = window.SetDate()
        //   window.console.log(result, '是否打印成功')
        //   if(result == '00'){
        //     _this.$message({
        //       message: '打印成功！',
        //       type: 'success'
        //     });
        //     _this.$router.push("/index")
        //   }else{
        //     _this.$message({
        //       message: '打印失敗！',
        //       type: 'warning'
        //     });
        //   }
        // }, 5000)
        // this.seatData = this.seatData.map(item => {
        //   item.x.forEach(item1 => {
        //     this.$set(item1, 'choose', false)
        //   });
        //   return item
        // })
      })
      
    },
  },
  mounted() {
    
    // 数据获取
    

      // 使用刚指定的配置项和数据显示图表。
      
  },
  
  destroyed(){
    removeTicType()
    store.commit("changeAmount",{})
  },
  
  computed: {
    // 用户信息
    userInfo(){
      return this.$store.state.userInfo
    }, 

    //頭部信息
    validate(){
      return this.$store.state.senceData
    },

    //金額
    amount(){
      return this.$store.state.amount
    }
  },
  created(){
    let arr2 = JSON.parse(getSeatData())
    window.console.log(arr2)
    // arr2.forEach((item, index) => {
    //   this.seatArr[index].position = item.position || ''
    // })
    this.position = arr2.map(item => {
      return {position: item.position}
    })
    // this.getInitData()
  },
};
</script>

<style lang="less">
.check-tic {
  height: calc(100% - 40px);
  background: linear-gradient(
      225deg,
      #0c0c0c,
      #1b3641
    );
  main{
    box-sizing:border-box;
    padding: 5px 20px; 
    height: calc(100% - 100px);
    background: linear-gradient(
      225deg,
      #0c0c0c,
      #1b3641
    );
    width: 100%;
    .seat{
        height: 50%;
        width: 50%;
        border: 1px solid #008b9f;
        margin: 8% auto 0;
        padding: 5%;
        p{
            color: #fff;
            margin: 10px 0 0 70px;
            font-size: 24px;
        }
    }
    .bottom-btn{
        margin-top: 2%;
        text-align: center;
        .el-button{
            margin: 0 5%;
            span{
                font-size: 18px;
            }
        }
    }
  }
  
}
</style>
