<template>
<div class="chart-container">
  <cc-header :head-data="headData"></cc-header>
  <main>
    <div class="left-hall">
      <div v-for="(item, index) in tableData" :key="index" class="hall_item">
        <h1 :style="{'background-color': item.color}">{{item.hall}}</h1>
        <ul v-for="(itemChild, indexChild) in item.child" :key="indexChild">
          <li @click="ticTypeDetail(item,itemChild)" :class="{ actives: itemChild.active }">
            <p>{{itemChild ? itemChild.movie.version.name : ""}}</p>
            <p class="film_name">{{itemChild ? itemChild.movie.name : ""}}
              <!-- <span v-if="itemChild.active">222</span> -->
            </p>
            <p class="time">{{itemChild.start_time}}</p>
          </li>
        </ul>
      </div>
    </div>
  </main>
  <!-- <div class="right-fixed">
      <h1>場次時間</h1>
      <div v-for="(item, index) in time" :key="index">
        {{item}}
      </div>
    </div> -->
  <cc-footer></cc-footer>
</div>
</template>

<script>
// 导入 echarts
// import echarts from "echarts";
// import pageHead from "../pageHead.vue";
// 导入接口
// import { setSenceData, removeSenceData} from "@/utils/dataCross";
import store from '@/store/store'
export default {
  data() {
    return {
      tableData: [{
          hallId: 1,
          hall: 'A廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
        {
          hall: 'B廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
        {
          hall: 'C廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
        {
          hall: 'D廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
        {
          hall: 'E廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
        {
          hall: 'F廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
        {
          hall: 'G廰',
          child: [{
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
            {
              type: '數位版',
              filmName: '少年pai漂流記（普）'
            },
          ]
        },
      ],
      headData: {}, //頭部數據
    };
  },
  methods: {
    // 塞入颜色
    putColor() {
      const color = ['#2fd393', '#e15ed9', '#f7673b', '#3f8af9', '#ff8400', '#ff8ee0'];
      this.tableData.forEach((item, index) => {
        item.color = color[index % color.length];
      })
    },

    //獲取播放聼和場次數據
    getInitData() {
      this.$api.request.getRooms().then(res => {
        this.tableData = res
        this.putColor()
        // let headArr = JSON.parse(JSON.stringify(this.tableData))
        // window.console.log(headArr,'tableData')
        // if(this.tableData[0].child.length){
        //   this.tableData[0].child[0].active = true
        // }
        // if(headArr[0].child.length){
        //   let obj = headArr[0]
        //   // delete headArr[0].child[0].active
        //   obj.child2 = headArr[0].child[0]
        //   obj.filmSence = obj.child2.movie.name + '-' + obj.child2.movie.version.name + '-' + obj.child2.start_time
        //   store.commit("changeSenceData", obj)
        // }
      })
    },

    //獲取預售票播放聼和場次數據
    getPreInitData() {
      this.$api.request.pregetRooms({
        date: this.$route.query.time
      }).then((res) => {
        this.tableData = res
        this.putColor()
      })
    },

    //點擊跳至場次詳情
    ticTypeDetail(item, itemChild) {
      this.tableData.forEach(item => {
        item.child.forEach(item2 => {
          if (item2.id === itemChild.id) {
            this.$set(item2, 'active', true)
            // item2.active = true
          } else {
            // item2.active = false
            this.$set(item2, 'active', false)
          }
        })
      })
      let itemobj = JSON.parse(JSON.stringify(item))
      delete itemobj.child
      itemobj.child2 = itemChild
      itemobj.filmSence = itemobj.child2.movie.name + '-' + itemobj.child2.movie.version.name + '-' + itemobj.child2.start_time
      store.commit("changeSenceData", itemobj)
    },

  },
  mounted() {
//old
//  if (this.$route.query.time || this.$route.query.id) {
//         this.getPreInitData()
//       } else {
//         this.getInitData();
//       }

//new
    window.console.log('路由参数', this.$route.query.id)
    // 数据获取
    if (this.$route.query.time != undefined) {
      window.localStorage.setItem('tmpid', this.$route.query.id)
      window.localStorage.setItem('tmptime', this.$route.query.time)
      if (this.$route.query.time || this.$route.query.id) {
        this.getPreInitData()
      } else {
        this.getInitData();
      }
    } else {
      if (window.localStorage.getItem('tmptime') || window.localStorage.getItem('tmpid')) {
        this.getPreInitData()
      } else {
        this.getInitData();
      }
    }
  },
  components: {
    // pageHead
  },
  created() {
    // this.putColor();
    // this.getInitData();
    // window.console.log(this.$route.query.time, this.$route.query.id)
    // if(this.$route.query.time || this.$route.query.id){
    //   this.getPreInitData()
    // }else{
    //   this.getInitData();
    // }
  },
};
</script>

<style lang="less">
.chart-container {
  height: calc(100% - 40px);
  background: linear-gradient( 225deg, #0c0c0c, #1b3641);
  main {
    padding: 5px 0;
    float: left;
    height: calc(100% - 227px);
    background: linear-gradient( 225deg, #0c0c0c, #1b3641);
    width: 100%;
    .left-hall {
      white-space: nowrap; // overflow: scroll;
      overflow-x: scroll;
      width: 100%;
      height: 100%;
      .hall_item {
        display: inline-block; // float: left;
        width: 200px;
        min-height: 50px;
        background: #000;
        margin: 0 4px;
        vertical-align: top;
        h1 {
          line-height: 50px;
          font-weight: 400;
          color: #fff;
          font-size: 22px;
          height: 50px;
          text-align: center;
        }
        ul {
          li {
            min-height: 35px;
            background: #eee;
            margin-top: 4px;
            padding: 5px;
            border-radius: 2px;
            cursor: pointer;
            p {
              margin: 3px 0;
              font-size: 13px;
              span {
                margin-left: 10px
              }
            }
            .film_name {
              font-weight: bold;
              letter-spacing: 2px;
            }
            .time {
              color: rgb(2, 79, 223)
            }
          }
        }
        .actives {
          background: rgb(228, 223, 160);
        }
      }
    }
  }
  .right-fixed {
    height: 100%;
    width: 130px;
    float: right;
    margin-top: 5px;
    padding: 0 10px;
    background: linear-gradient( 150deg, #0c0c0c, #1b3641);
    h1 {
      line-height: 50px;
      font-weight: 400;
      color: #fff;
      font-size: 18px; // height: 100%;
      text-align: center;
    }
    div {
      color: #777;
      height: 35px;
      background: #eee;
      margin-top: 4px;
      padding: 5px;
      border-radius: 2px;
      text-align: center;
      line-height: 35px;
    }
  }
}
</style>
