<template>
  <el-container class="index-container">
    <el-header class="my-header" height="100px">
      <div class="left">
        <!-- 图标 -->
        <!-- <i @click="isCollapse = !isCollapse" class="el-icon-s-fold icon"></i> -->
        <img class="logo" :src="userInfo.logo" alt />
        <!-- <span class="title">toy售票系統</span> -->
      </div>
      <div class="center">
        <p id="title" ref="moniter" @click="moniter">
          影城售票系統
          <!-- <input type="text" id="test" style="height:40px;"/>
          <input type="number" id="test" style="height:40px;"/> -->
        </p>
      </div>
      <div class="right">
        <p>
          <span class="user-name"
            >{{ userInfo.time }} 【{{ userInfo.name }}】你好</span
          >
          <el-button type="primary" @click="close" size="small">關機</el-button>
        </p>
      </div>
    </el-header>
    <el-main>
      <el-button @click="online">網路售票</el-button>
      <el-button @click="goSellTic((type = 1))">訂票</el-button>
      <el-button @click="returnTic">退票</el-button>
      <el-button @click="dateFun">預售票</el-button>
    </el-main>

    <!-- 預售票 -->
    <el-dialog
      title="選擇日期"
      :visible.sync="dateVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-date-picker
        class="dates"
        v-model="dateTic"
        type="date"
        :picker-options="startDateOptions"
        value-format="yyyy-MM-dd"
        placeholder="請選擇日期"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dateVisible = false">取 消</el-button>
        <el-button type="primary" @click="goSellTic((type = 2))"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 座位快查 -->
    <el-dialog
      title="選擇日期"
      :visible.sync="seatDateVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-date-picker
        class="dates"
        v-model="dateSeat"
        type="date"
        :picker-options="seatDateOptions"
        :default-value="defaultDate"
        value-format="yyyy-MM-dd"
        placeholder="請選擇日期"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleSeat">取 消</el-button>
        <el-button type="primary" @click="goSellTic((type = 3))"
          >確 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="退票"
      :visible.sync="returnTicVisible"
      @close="hide"
      width="98%"
      :close-on-click-modal="false"
      class="return-tic"
      style="height: 68%"
      modal="modal"
    >
      <el-row :gutter="60">
        <el-col :span="14">
          <div class="grid-content bg-purple">
            <el-table
              :data="tableData"
              empty-text="暫無數據"
              stripe
              style="width: 100%"
            >
              <el-table-column prop="order.orderno" label="訂單號" width="220">
              </el-table-column>
              <el-table-column prop="staff.name" label="經手人" width="130">
              </el-table-column>
              <el-table-column
                prop="order.total_price"
                label="金額"
                width="100"
              >
              </el-table-column>
              <el-table-column prop="movie.name" label="電影" width="150">
              </el-table-column>
              <el-table-column
                prop="changci.start_time"
                label="場次時間"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="movie.version.name"
                label="電影版本"
                width="100"
              >
              </el-table-column>
              <el-table-column prop="updated_at" label="退票時間" width="180">
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="grid-content bg-purple">
            <!-- 票券信息： -->
            <!-- <input v-model="orderNo" ref="back" id="world" @keyup="getQuery" @focus="show" autofocus="true" clearable data-layout="normal" label="票券信息" placeholder="請輸入票券號" style="width: 250px"/> -->
            <!-- <input v-model="orderNo" ref="back" id="world" autofocus="true" clearable data-layout="normal" label="票券信息" placeholder="請輸入票券號" style="width: 250px" /> -->

            <el-input
              v-model="orderNo"
              @focus="show"
              autofocus="true"
              clearable
              data-layout="normal"
              label="票券信息"
              placeholder="請輸入票券號"
              style="width: 250px"
            ></el-input>
            <!-- <el-input v-model="orderNo" ref="back" id="world" @input="change" @blur="change" @touchstart="change" @focus="show" autofocus clearable data-layout="normal" label="票券信息" placeholder="請輸入票券號" style="width: 250px"></el-input> -->

            <el-button
              type="primary"
              style="margin-left: 15px"
              @click="getQuery"
              >查詢</el-button
            >
            <!-- <el-button  type="primary" style="margin-left: 15px" @click="hide">键盘</el-button> -->
            <div class="data">
              <p>
                <span class="fir">所在廳：</span
                ><span class="sec">{{ orderData.order.room.name }}</span>
              </p>
              <p>
                <span class="fir">電影：</span
                ><span class="sec"
                  >{{ orderData.order.movie.name }}
                  {{
                    orderData.order && orderData.order.changci
                      ? orderData.order.changci.start_date
                      : ""
                  }}
                  {{
                    orderData.order && orderData.order.changci
                      ? orderData.order.changci.start_time
                      : ""
                  }}</span
                >
              </p>
              <p v-if="typeof(orderData.order.seats) == 'string'">
                <span class="fir">座位：</span>
                <span class="sec" v-for="item in orderData.order.orderseats" :key="item.seat_id">{{item.position}}</span> 
                <!--<span
                  class="sec"
                  v-for="item in orderData.order.seats"
                  :key="item.position"
                >
                {{item.position}}
                   {{ item.position.split("-")[1] }}排
                  {{ item.position.split("-")[0] }}號 -->
                <!--</span>-->
              </p>
              <p v-if="typeof(orderData.order.seats) != 'string'">
                <span class="fir">座位：</span>
                <span
                  class="sec"
                  v-for="item in orderData.order.seats"
                  :key="item.position"
                >
                {{item.position}}
                  <!-- {{ item.position.split("-")[1] }}排
                  {{ item.position.split("-")[0] }}號 -->
                </span>
              </p>
              <p>
                <span class="fir">支付金額：</span
                ><span class="sec">{{ orderData.order.total_price }}</span>
              </p>
              <p>
                <span class="fir">是否可退票：</span>
                <span class="sec" v-if="orderData.can_reorder">是</span>
                <span v-else class="sec">否</span>
              </p>
              <p style="display:none">{{tips}}</p>
              <p>
                <el-button
                  type="primary"
                  style="margin-left: 15px"
                  @click="sureReturn"
                  >確定退票</el-button
                >
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-footer height="250px">
      <div class="left">
        <el-button @click="seatFind">座位快查</el-button>
        <el-button @click="reloadx">刷新</el-button>
      </div>
      <div class="right" style="width: 340px">
        <el-button @click="reloadx">刷新</el-button>
        <el-button @click="jiaoban">交班</el-button>
        <el-button @click="logout">登出</el-button>
        <span class="box" @click="openCash"
          ><i class="iconfont">&#xe600;</i><br />錢箱</span
        >
        <!-- <el-button @click="close">關機</el-button> -->
      </div>
    </el-footer>
    <div class="jiaoban" v-if="showChangeShfD">
      <h2>交班</h2>
      <p>
        交班人員：<span>{{ changeShiftData.name }}</span>
      </p>
      <p>
        登錄時間：<span>{{ changeShiftData.login_at }}</span>
      </p>
      <p>
        交班時間：<span>{{ changeShiftData.updated_at }}</span>
      </p>
      <p>
        售退票數：<span>{{ returnTicNum }}</span>
      </p>
      <p>
        應收金額：<span>{{ totalMoney }}</span>
      </p>
      <button @click="showChangeShfD = false" class="cancle">取消</button>
      <button @click="sureChangeShfD">確定</button>
    </div>

    <!-- new add -->
    <!-- <vue-touch-keyboard style="z-index:9999;" :options="options" v-if="visible" :layout="layout" :cancel="hide" :accept="accept" :input="input" /> -->
  </el-container>
</template>

<script>
import { removeToken } from "../../utils/token";
import store from "@/store/store";
import moment from "moment";
//键盘
import Vue from "vue";
// import VueTouchKeyboard from "vue-touch-keyboard";
// import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"; // load default style
// Vue.use(VueTouchKeyboard);
// Vue.use(style);

export default {
  name: "index",
  data() {
    return {
      input: "",
      options: {
        useKbEvents: false,
        preventClickEvent: false,
      },
      layout: "normal",
      visible: false,
      modal: false,
      //上面是键盘

      seatDateVisible: false, //座位快查時間
      defaultDate: "",
      startDateOptions: {}, //預售時間限制
      seatDateOptions: {}, //座位快查時間限制
      dateTic: "", //日期
      dateSeat: "",
      dateVisible: false, //顯示時間彈窗
      // 是否折叠
      isCollapse: false,
      // 用户信息
      // userInfo: {}
      returnTicVisible: false,
      changeShiftData: {
        name: "",
        login_at: "",
        updated_at: "",
        avatar: "",
      },
      totalMoney: 0,
      returnTicNum: "",
      showChangeShfD: false,
      seat: false,
      orderNo: "",
      orderData: {
        can_reorder: "",
        changci: {},
        order: {
          seats: [],
          name: "",
          room: {
            name: "",
          },
          movie: {
            name: "",
          },
        },
      },
      tableData: [],
      tips:""
    };
  },
  watch: {
    // orderNo(val, oval){
    //   window.console.log(val,oval,'202004170151356745679')
    // }
  },
  computed: {
    // 用户信息
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    reloadx() {
      window.location.reload();
    },
    moniter() {
      this.$refs.moniter.click();
      // alert('clicked')
    },
    change() {
      // window.console.log('e',e)
      // this.orderNo=e
      this.$forceUpdate();
    },
    accept(text) {
      window.console.log(text);
      // alert("Input text: " + text);
      this.hide();
    },
    show(e) {
      // window.console.log(2322)
      // this.input = e.target;
      // this.layout = e.target.dataset.layout;
      // if (!this.visible)
      //   this.visible = true
    },
    hide() {
      this.visible = !this.visible;
    },
    //打開錢箱
    openCash() {
      window.console.log("打開錢箱主頁");
      window.CashboxOpen();
    },
    //確定退票
    sureReturn() {
      this.$api.request
        .reorder({
          orderno: this.orderNo,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
    },

    //查詢
    getQuery() {
      // alert('inside');
      this.$api.request
        .getQuery({
          orderno: this.orderNo,
          page: 1,
        })
        .then((res) => {
          if (Object.keys(res.order).length) {
            this.orderData = res;
            this.tips= this.orderData.order.seats.slice(1, -1)
            this.orderData.order.seats = JSON.parse(this.orderData.order.seats.slice(1, -1));
          }
        });
    },

    // disabledDates(){
    //   window.console.log(this.dateSeat)
    //   if (Date.now() <= this.dateSeat) {
    //     return true
    //   }
    //   return false
    // return {
    //     disabledDate(time){
    //       return time.getTime() < Date.now()//开始时间不选时，结束时间最大值小于等于当天
    //       }
    //     }
    // },

    // disabledDateSeat(){
    // let self = this
    // return {
    //   disabledDate(time){
    //     return time.getTime() <= Date.now()//开始时间不选时，结束时间最大值小于等于当天
    //     }
    //   }
    // if (Date.now() < this.date) {
    //   return true
    // }
    // return false
    // },
    cancleSeat() {
      this.seatDateVisible = false;
      store.commit("changeFindSeat", false);
    },

    //座位快查
    seatFind() {
      this.seatDateVisible = true;
      store.commit("changeFindSeat", true);
      this.seat = true;
    },

    //取消選擇日期
    cancleGetDate() {
      this.dateVisible = false;
      this.seat = false;
    },
    // goSellTics(){
    // },
    //確定交班
    sureChangeShfD() {
      this.$api.request.jiaobans().then((res) => {
        // window.console.log(res)
        if (res.msg == "交班成功") {
          removeToken();
          // 清除Vuex里userInfo信息
          this.$store.state.userInfo = {};
          // 跳转到登录页
          this.$router.push("/login");
        }
      });
    },

    //去售票厛
    goSellTic(type) {
      // if(type === 1){
      //   if(this.date == ''){
      //     this.$message({
      //       message: '請選擇日期！',
      //       type: 'warning'
      //     });
      //     return false
      //   }
      //   if(this.seat){
      //     this.$router.push({path:"/chart", query:{id : 777, time: this.date }})
      //   }else{
      //     this.$router.push({path:"/chart", query:{time: this.date }})
      //   }

      //   this.dateVisible = false;
      // }else{
      //   this.$router.push("/chart")
      // }
      if (type === 1) {
        window.localStorage.removeItem("tmpid");
        window.localStorage.removeItem("tmptime");
        this.$router.push("/chart");
      } else if (type === 2) {
        this.$router.push({
          path: "/chart",
          query: {
            time: this.dateTic,
          },
        });
      } else {
        this.$router.push({
          path: "/chart",
          query: {
            id: 777,
            time: this.dateSeat,
          },
        });
      }
    },

    //交班
    jiaoban() {
      this.$api.request.jiaoban().then((res) => {
        let data = res;
        this.changeShiftData = data.info;
        this.changeShiftData.updated_at = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.totalMoney = data.total_money;
        this.returnTicNum = data.returnTicNum;
        this.showChangeShfD = true;
      });
    },
    online() {
      this.$router.push("/online");
    },
    //退票
    returnTic() {
      this.returnTicVisible = true;

      // this.$nextTick(() => {
      //   this.$refs.back.focus()
      // })
      // setTimeout(function() {
      //   window.console.log('模拟鼠标点击')
      //   var e = document.createEvent('MouseEvents')
      //   e.initEvent('click', true, true)
      //   document.getElementById('world').dispatchEvent(e)
      // }, 1000)

      this.$api.request
        .query({
          page: 1,
        })
        .then((res) => {
          window.console.log(res, 22222);
          if (res.already.data.length) {
            this.tableData = res.already.data;
          }
        });
    },

    //預售時間彈窗
    dateFun() {
      this.dateVisible = true;
    },

    //關機
    close() {
      this.$confirm("是否確認關機？", "確認信息", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      })
        .then(() => {
          localStorage.removeItem("lock");
          window.NanUI.hostWindow.close();
        })
        .catch(() => {});
    },
    // 退出
    logout() {
      this.$confirm("是否現在離開頁面？", "確認信息", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      })
        .then(() => {
          localStorage.removeItem("lock");
          this.$api.request
            .loginOut()
            .then(() => {
              removeToken();
              // 清除Vuex里userInfo信息
              this.$store.state.userInfo = {};
              // 跳转到登录页
              this.$router.push("/login");
            })
            .catch((error) => {
              window.console.log(error);
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: action === 'cancel'
          //     ? '放弃保存并离开页面'
          //     : '停留在当前页面'
          // })
        });
    },
    // scan() {
    //   var b = "";
    //   var _this = this;
    //   document.onkeydown = function() {
    //     if (event.keyCode != 13) {
    //       var bizCode = String.fromCharCode(event.keyCode);
    //       if (event.keyCode >= 48 && event.keyCode <= 122) {
    //         b = b + bizCode;
    //       }
    //     } else {
    //       b = "";
    //     }
    //     _this.orderNo = b;
    //   };
    // }
  },
  created() {
    store.commit("changeFindSeat", false);
    store.commit("changeSenceData", {});
    window.console.log(moment().format("YYYY-MM-DD"), 343434);

    // this.scan();

    this.dateTic = moment().add(1, "days").format("YYYY-MM-DD");
    this.dateSeat = moment().format("YYYY-MM-DD");
    //預售時間限制
    this.startDateOptions.disabledDate = function disabledDated(time) {
      return time.getTime() < Date.now(); //如果没有后面的-8.64e6就是可以选择当天的
    };

    //座位快查限制
    this.seatDateOptions.disabledDate = function disabledDates(time) {
      return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e6就是可以选择当天的
    };

    window.console.log("information", this.$route.params.refresh);
    if (this.$route.params.refresh == 1) {
      localStorage.setItem("lock", 1);
      location.reload(true);
      // window.NanUI.hostWindow.maximize()

      // window.NanUI.hostWindow.maximize()
    }
  },
  destroyed() {},
  mounted() {
    // window.NanUI.hostWindow.maximize()
  },
};
</script>

<style lang="less" scoped>
.index-container {
  height: 100%;
  width: 100%;
  position: relative;
  .jiaoban {
    padding: 20px;
    height: 25%;
    width: 30%;
    position: absolute;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    h4 {
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      padding-bottom: 5px;
      span {
        margin-left: 10px;
      }
    }
    button {
      display: block;
      float: right;
      padding: 5px 10px;
      background: #008b9f;
      color: #fff;
      margin-left: 10px;
    }
    .cancle {
      background: #999;
    }
  }
  .dates {
    width: 100%;
    .el-input__inner {
    }
  }
  .my-header {
    background: #1b3641;
    border-bottom: 2px solid #008b9f;
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
    }
    .left,
    .center {
      display: flex;
      align-items: center;
      .icon {
        font-size: 24px;
        margin-right: 22px;
      }
      .logo {
        // width: 33px;
        height: 80px;
        margin-right: 11px;
      }
      .title {
        font-size: 22px;
        color: #008b9f;
      }
    }
    .center {
      font-size: 36px;
      color: #008b9f;
      letter-spacing: 10px;
      font-weight: 600;
      p {
        text-align: center;
        width: 100%;
      }
    }
    .right {
      p {
        text-align: right;
        width: 100%;
      }
      display: flex;
      align-items: center;
      .user-icon {
        border-radius: 50%;
        width: 43px;
        height: 43px;
        margin-right: 15px;
      }
      .user-name {
        color: #008b9f;
        font-size: 14px; // margin-right: 38px;
      }
      .el-button--primary {
        background: #008b9f;
        border: none;
        margin-left: 5px;
      }
    }
  }
  .el-main {
    align-items: center;
    height: 60% !important;
    display: flex;
    background: linear-gradient(225deg, #0c0c0c, #1b3641);
    .el-button {
      flex: 1;
      margin: 0 100px;
      height: 25%;
      border-radius: 6px;
      font-size: 40px;
      font-weight: 600; // background: #000;
      // background: #50a0c2;
      background: linear-gradient(250deg, #50a0c2, #a7dff8, #50a0c2);
      color: #1b3641;
      border-color: #008b9f; // letter-spacing: 12px;
      padding: 5px 10px;
    } // .el-button--primary{
    //   background: #008b9f;
    //   border: none;
    // }
  }
  .el-footer {
    border-top: 2px solid #008b9f;
    background: #1b3641;
    display: flex;
    justify-content: space-between;
    .el-button,
    span {
      height: 23%; // width: 40%;
      border-radius: 6px;
      font-size: 24px; // font-weight: 600;
      // background: #000;
      background: #016170;
      color: #50a0c2;
      border: 0; // letter-spacing: 8px;
      padding: 5px 20px;
    }
    div {
      margin-top: 20px;
    }
    .right {
      width: 260px;
      height: 60%; // display: flex;
      // flex-wrap: wrap;
      // justify-content: flex-end;
      .el-button,
      .box {
        margin: 0 10px;
        cursor: pointer; // font-size: 20px;
      }
      .box {
        display: inline-block;
        background: none; // font-size: 20px;
        text-align: center;
        margin-top: 15px;
        i {
          font-size: 50px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
  padding-right: 0 !important;
}

.dates {
  width: 100%;
  .el-input__inner {
    height: 60px;
    font-size: 20px;
  }
}

.el-date-picker {
  width: 500px !important;
  .el-picker-panel__body > .el-picker-panel__content {
    width: 94%;
  }
  .el-date-table {
    font-size: 18px;
    td {
      padding: 8px 2px;
    }
  }
}

.return-tic {
  // height: 80%;
  .data {
    height: 270px;
    margin-top: 20px;
    border: 1px solid #ebeef5;
    padding: 10px;
    p {
      margin: 10px 0 20px;
      border-bottom: 1px solid #ebeef5;
      &:last-child {
        padding-top: 10px;
        border-bottom: 0;
        .el-button {
          float: right;
        }
      }
      span {
        display: inline-block;
        width: 100px;
      }
      .sec {
        text-align: right;
        width: 240px;
      }
    } // p>span:first-child{
    //   text-align: right;
    // }
    // float: left;
  }
}
</style>