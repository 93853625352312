<template>
<div id="film-app">
  <div class="top">
    <div></div>
    <p>
      <i class="iconfont" @click="small">&#xe606;</i>
      <i class="iconfont" @click="max">&#xe620;</i>
      <i class="iconfont" @click="close">&#xe70f;</i>
    </p>
  </div>
  <!-- <keep-alive> -->
  <router-view></router-view>
  <!-- </keep-alive> -->
</div>
</template>

<script>
import {
  evt
} from './utils/veriabel'
export default {
  name: 'app',
  data() {
    return {}
  },
  methods: {
    small() {
      window.NanUI.hostWindow.minimize()
    },
    max() {
      var lock = localStorage.removeItem('lock')
      if (lock != undefined) {
     
        lock = lock == 0 ? 1 : 0;
        localStorage.setItem('lock', lock)
      }
      window.NanUI.hostWindow.maximize()
    },
    close() {
      localStorage.removeItem('lock')
      window.NanUI.hostWindow.close()
    }
  },
  created() {

    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("messageStore", JSON.stringify(this.$store.state))
    })

    //在页面加载时读取localStorage里的状态信息
    localStorage.getItem("messageStore") && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem("messageStore"))));
    if (localStorage.getItem('lock') == 0 || localStorage.getItem('lock') == undefined) {
      this.max()
    }
  },
  mounted() {
    this.unbind = evt.add(document, 'keydown', e => {
      // Ctrl + F5 强制刷新
      if (e.ctrlKey && e.which === 116) {
        location.reload(true)
        localStorage.setItem('lock',1)
        this.max()
        e.preventDefault()
      }
      if (e.ctrlKey && e.altKey && e.keyCode == 38) {
        this.machineCodeShow = !this.machineCodeShow
      }
    })
    this.unbind = evt.add(document, 'keydown', e => {
      if (e.which === 123) {
        global.ShowDevTools && global.ShowDevTools()
      }
    })
  },
  beforeDestroy() {
    localStorage.removeItem('lock')
    this.unbind && this.unbind()
  }
}
</script>

<style lang="scss">
#film-app {
  height: 100%; // -webkit-app-region: drag;
  .top {
    height: 40px; // background: linear-gradient(
    //   225deg,
    //   #0c0c0c,
    // #1b3641
    // );
    background: #1b3641;
    cursor: pointer;
    color: #fff;
    div {
      display: inline-block;
      width: 80%;
      height: 100%;
      -webkit-app-region: drag;
    }
    p {
      float: right; // vertical-align: top;
      display: inline-block;
      -webkit-app-region: no-drag;
      text-align: right;
      line-height: 37px;
      i {
        margin: 3px 10px;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
</style>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
