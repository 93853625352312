import http from "@/utils/http"
// import get from "@/utils/http"

export default{
    // 登录 data参数
    login: (params, callback) => {
        return http.post('api/login', params, callback)
    },
    // 登出
    loginOut: (params, callback) => {
        return http.post('api/user/logout', params, callback)
    },
    // 交班信息
    jiaoban: (params, callback) => {
        return http.get('api/user/jiaoban', params, callback)
    },

    // 交班信息s
    jiaobans: (params, callback) => {
        return http.post('api/user/jiaoban', params, callback)
    },
    
    //訂票
    //獲取播放聼和場次數據
    getRooms: (params, callback) => {
        return http.get('api/order/getRooms', params, callback)
    },

    //獲取場次下的票種和其他信息
    getSeatTypes: (params, callback) => {
        return http.get('api/order/getSeatTypes', params, callback)
    },

    //獲取場次下所有已訂購和未訂購數據
    getSeats: (params, callback) => {
        return http.get('api/order/getSeats', params, callback)
    },

    //獲取指定购买訂單詳情
    creates: (params, callback) => {
        return http.get('api/order/create', params, callback)
    },

    //創建訂單
    create: (params, callback) => {
        return http.post('api/order/create', params, callback)
    },

    //預定票
    //获取指定日期播放厅和场次数据
    pregetRooms: (params, callback) => {
        window.console.log(params, 8999999999999)
        return http.get('api/preorder/getRooms', params, callback)
    },

    //获取指定日期和场次下的票种和其它信息
    pregetSeatTypes: (params, callback) => {
        return http.get('api/preorder/getSeatTypes', params, callback)
    },

    //获取指定日期下，场次下的所有已订购和未订购的数据
    pregetSeats: (params, callback) => {
        return http.get('api/preorder/getSeats', params, callback)
    },

    //獲取指定预购訂單詳情
    precreates: (params, callback) => {
        return http.get('api/preorder/create', params, callback)
    },

    //创建預订单
    precreate: (params, callback) => {
        return http.post('api/preorder/create', params, callback)
    },

    //查詢當日退票信息
    query: (params, callback) => {
        return http.get('api/reorder/query', params, callback)
    },
     //查詢此退票信息
     getQuery: (params, callback) => {
        return http.post('api/reorder/query', params, callback)
    },

    //查詢此退票信息
    reorder: (params, callback) => {
        return http.post('api/reorder/reorder', params, callback)
    },
    //查詢網絡訂單信息
    queryOnline:(params, callback) => {
        return http.post('api/online/queryOnline', params, callback)
    },
    //查詢網絡訂單列表
    queryOnlineList:(params, callback) => {
        return http.post('api/online/queryOnlineList', params, callback)
    },
    //支付網絡訂單確認
    payOnlineOrder:(params, callback) => {
        return http.post('api/online/payOnlineOrder', params, callback)
    }
}