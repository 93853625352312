<template>
  <div :class="[{'login-key': visible}, 'login-box']">
    <!-- <el-button type="info" class="book">訂票</el-button> -->
    <div class="form-box">
      <div class="title-box">
        <!-- <img src="../../assets/矢量智能对象 拷贝 9.png" alt />
        <span class="title">toy购票系统</span>
        <i></i> -->
        <span class="sub-title">請登入使用者帳號（未登入）</span>
      </div>

      <!-- 登录表单 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-form">
        <el-form-item prop="phone" label="帳號">
          <el-input v-model="ruleForm.phone" placeholder="請輸入帳號" prefix-icon="el-icon-user"  @focus="show"  data-layout="normal"></el-input>
        </el-form-item>

        <el-form-item prop="password"  label="密碼">
          <el-input
           @focus="show"
            data-layout="normal"
            v-model="ruleForm.password"
            placeholder="請輸入密碼"
            show-password
            prefix-icon="el-icon-lock"
          ></el-input>

        </el-form-item>
        
        <el-form-item class="btn-login">
          <el-button type="primary" @click="submForm">確定</el-button>
          <!-- <el-button type="primary" @click="submForm">離開</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <vue-touch-keyboard :options="options" v-if="visible" :layout="layout" :cancel="hide" :accept="accept" :input="input" />
    <!-- <el-button type="info" class="book">預售票</el-button> -->
    <!-- <img class="login-pic" src="../../assets/login_banner_ele.png" alt /> -->
  </div>
</template>

<script>
import store from '@/store/store'
import http from "@/utils/axios"
import moment from 'moment';
import Vue from 'vue'
// 导入登录接口
// import { login } from "../../api/login.js";
// 导入token 保存方法
import { setToken } from "../../utils/token"
import VueTouchKeyboard from "vue-touch-keyboard";
import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"; // load default style

Vue.use(VueTouchKeyboard);
Vue.use(style);
export default {
  name: "login",
  data() {
    //  自定义验证规则--手机号
    var checkphone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("帳號不能空白"));
      }
    };
    return {
      input: null,
      options: {
        useKbEvents: false,
        preventClickEvent: false
      },  
      layout: "normal",
      visible: false,
      //  登录表单数据
      ruleForm: {
        phone: "",
        password: "",
        captcha: "",
        checked: false
      },
      // 登录表单验证规则
      rules: {
        phone: [{ required: true, validator: checkphone, trigger: "blur" }],
        password: [
          { required: true, message: "密碼不能空白", trigger: "change" },
          {
            min: 6,
            max: 18,
            message: "長度在 6 到 18 個字符",
            trigger: "change"
          }
        ],
      },
      //  是否显示dialog对话框
      dialogFormVisible: false,
      // dialog宽度
      formLabelWidth: "80px",
    };
  },
  created(){
    // setToken('123')
  },
  methods: {
    accept(text) {
      window.console.log(text)
      // alert("Input text: " + text);
      this.hide();
    },

    show(e) {
      window.console.log(2322)
      this.input = e.target;
      this.layout = e.target.dataset.layout;

      if (!this.visible)
        this.visible = true
    },

    hide() {
      this.visible = false;
    },
    submForm() {
      window.console.log(this.$api,222)
      this.$api.request.login({username: this.ruleForm.phone,password: this.ruleForm.password}).then((res)=>{
        setToken(res.data.api_token)
        http.defaults.headers.Authorization = `Bearer ${res.data.api_token}`
        store.commit("changeUserInfo",{
          logo: res.data.theater.logo_url,
          name: res.data.username, 
          time: moment().format("YYYY-MM-DD HH:mm:ss"),
          userId: res.data.id,
          theaterData: res.data.theater})
        store.commit("changeSenceData",{})
        this.$router.push("/index")
        // location.reload(true)
        
      }).catch(error => {
        window.console.log(error)
      })
    },
  }
};
</script>

<style lang="less">
.login-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(
    225deg,
    #0c0c0c,
   #1b3641
  );
  .el-button--primary{
    background: #008b9f;
    border: none;
  }
  height: 100%;
  width: 100%;
  .form-box {
    width: 478px;
    // height: 550px;
    background: rgba(245, 245, 245, 1);
    padding: 44px;
    box-sizing: border-box;
    .title-box {
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 17px;
        margin-right: 16px;
      }
      .title {
        font-size: 24px;
        margin-right: 14px;
      }
      i {
        width: 1px;
        height: 28px;
        background: rgba(199, 199, 199, 1);
        margin-right: 12px;
      }
      .sub-title {
        font-size: 22px;
        font-weight: 400;
        color: rgba(12, 12, 12, 1);
      }
    }
    .login-form {
      margin-top: 30px;
      .captcha {
        height: 40px;
        width: 100%;
      }
      .el-checkbox {
        display: flex;
        align-items: center;
        .el-checkbox__label {
          display: flex;
          align-items: center;
        }
      }
      .el-input__inner{
        height: 50px !important;
      }
      .el-button {
        width: 100%;
        margin: 0;
        
      }
      .btn-login{
        text-align: center;
        margin-top: 40px;
      }
      .el-button--primary:hover{
        background: #008b9f;
      }
      .register-button {
        margin-top: 30px;
      }
    }
  }
}
.login-key{
  display: block;
  padding-top: 20px;
  .form-box{
    padding: 25px 44px;
    margin-left: 33%;
    margin-bottom: 20px;
  }
}
</style>